import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import {
  Typography,
  Box,
  Tooltip,
  CircularProgress,
  IconButton,
  Grid,
} from '@material-ui/core';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import DeleteIcon from '../icons/DeleteIcon';
import UploadIcon from '../icons/UploadIcon';
import PaginationMaterialTable from '../elements/PaginationMaterialTable';
import {
  ORDER_STATUS_GROUP,
  ORDER_STATUS_GROUP_LABEL,
  AVAILABLE_COUNTRIES,
  COUNTRY_CODE_CL,
} from '../../helpers/constants';
import { t13s } from '../../translationKeys';
import Chip from '../elements/Chip';
import CountryFormatHelper from '../elements/CountryFormatHelper';
import UploadFileDialog from '../dialogs/UploadFileDialog';
import {
  deleteInvoicePdf,
  resetPdfWasUploadedState,
  uploadInvoicePdf,
} from '../../store/actions/orderActions';

const InvoiceTableOrderDetails = React.memo(props => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [showUploadPdfDialog, setShowUploadPdfDialog] = useState(false);
  const [invoiceIdToUpload, setInvoiceIdToUpload] = useState('');

  const { tableData, isLoading, countryId, pagination, updateInvoices } = props;
  const { totalData, page, pageSize } = pagination;

  const {
    resume,
    pdfIsDeleting,
    updatingInvoiceId,
    pdfIsUploading,
    pdfWasUploaded,
    pdfIsUploadingError,
  } = useSelector(state => state.orderState);

  const { IN_REVIEW, APPROVED, TRANSFERRED, PAID, REJECTED, RENEGOTIATION } =
    ORDER_STATUS_GROUP;

  const handleChangePage = (selectedPage, pageSize) => {
    updateInvoices(selectedPage, pageSize);
  };

  const handleDeletePdf = (invoiceId, pdfId) => {
    dispatch(deleteInvoicePdf(invoiceId, pdfId));
  };

  const handleShowPdfUploadDialog = invoiceId => {
    setInvoiceIdToUpload(invoiceId);
    setShowUploadPdfDialog(true);
  };

  const handleClosePdfUploadDialog = () => {
    setShowUploadPdfDialog(false);
    dispatch(resetPdfWasUploadedState());
  };

  useEffect(() => {
    if (pdfWasUploaded) {
      handleClosePdfUploadDialog();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdfWasUploaded]);

  const handleSubmitFile = pdf => {
    dispatch(uploadInvoicePdf(invoiceIdToUpload, pdf[0]?.file));
  };

  const getVariant = statusGroup => {
    let variant = '';
    switch (statusGroup) {
      case IN_REVIEW:
        variant = 'yellow';
        break;
      case APPROVED:
        variant = 'blue';
        break;
      case TRANSFERRED:
        variant = 'green';
        break;
      case PAID:
        variant = 'lightblue';
        break;
      case REJECTED:
        variant = 'red';
        break;
      case RENEGOTIATION:
        variant = 'purple';
        break;
      default:
        variant = 'default';
        break;
    }
    return variant;
  };

  const getColumns = () => {
    const columns = [
      {
        title: 'Folio',
        field: 'folio',
        type: 'numeric',
        width: '80px',
        align: 'left',
        render: invoice => (
          <Typography variant="body1" color="textSecondary" component="div">
            <Box fontWeight="fontWeightBold">{invoice.folio}</Box>
          </Typography>
        ),
      },
      {
        title: 'Nombre Pagador',
        field: 'payerName',
        type: 'string',
        align: 'left',
        render: invoice => (
          <Typography variant="body1" color="textPrimary" component="div">
            <Box fontWeight="fontWeightBold">
              {invoice.payerName.toUpperCase()}
            </Box>
          </Typography>
        ),
      },
      {
        title: t(t13s.LABEL.BUSINESS_IDENTIFIER),
        field: 'payerIdentifier',
        type: 'string',
        width: '160px',
        align: 'left',
        sorting: true,
        render: invoice => (
          <Typography
            variant="body1"
            color="textSecondary"
            component="div"
            noWrap
          >
            <Box fontWeight="fontWeightBold">{invoice.payerIdentifier}</Box>
          </Typography>
        ),
      },
      {
        title: 'Fecha de Emisión',
        field: 'issueDate',
        type: 'datetime',
        width: '160px',
        align: 'left',
        render: invoice => (
          <Typography
            variant="body1"
            color="textSecondary"
            component="div"
            noWrap
          >
            <Box fontWeight="fontWeightBold">
              <CountryFormatHelper
                value={invoice.issuedDate}
                variant="longdate"
                countryId={countryId}
              />
            </Box>
          </Typography>
        ),
      },
      {
        title: 'Fecha de vencimiento',
        field: 'expirationDate',
        type: 'datetime',
        width: '160px',
        align: 'left',
        render: invoice => (
          <Typography
            variant="body1"
            color="textSecondary"
            component="div"
            noWrap
          >
            <Box fontWeight="fontWeightBold">
              <CountryFormatHelper
                value={invoice.expirationDate}
                variant="longdate"
                countryId={countryId}
              />
            </Box>
          </Typography>
        ),
      },
      {
        title: 'Monto Total',
        field: 'totalAmount',
        type: 'numeric',
        width: '150px',
        render: invoice => (
          <Box display="inline-flex">
            {invoice.totalAmountCreditNotes ? (
              <Tooltip
                title={
                  <>
                    Monto nota de crédito: $
                    <CountryFormatHelper
                      value={invoice.totalAmountCreditNotes}
                      countryId={countryId}
                      variant="currency"
                    />
                  </>
                }
              >
                <WarningRoundedIcon color="error" fontSize="small" />
              </Tooltip>
            ) : null}
            <Typography variant="body1" color="textSecondary" component="div">
              <Box fontWeight="fontWeightBold">
                <CountryFormatHelper
                  value={invoice.totalAmount}
                  countryId={countryId}
                  variant="currency"
                  useFixedDecimalPrecision={2}
                  dataQa={`order-invoices-table-monto-total-string-${invoice?.tableData?.id}`}
                />
              </Box>
            </Typography>
          </Box>
        ),
      },
      {
        title: 'Tasa',
        type: 'number',
        width: '130px',
        align: 'left',
        render: invoice => (
          <Typography variant="body1" color="textSecondary" component="div">
            <Box fontWeight="fontWeightBold">
              <CountryFormatHelper
                value={invoice?.rate || resume?.baseRate}
                variant="percentage"
                useFixedDecimalPrecision={2}
                countryId={countryId}
              />
            </Box>
          </Typography>
        ),
      },
      {
        title: 'Estado',
        field: 'statusGroup',
        type: 'string',
        align: 'center',
        width: '160px',
        render: invoice => {
          const variant = getVariant(invoice.statusGroup);
          return (
            <Chip
              variant={variant}
              size="small"
              label={ORDER_STATUS_GROUP_LABEL[invoice.statusGroup]}
              data-qa={`order-invoices-table-estado-chip-${invoice?.tableData?.id}`}
            />
          );
        },
      },
    ];

    if (countryId === COUNTRY_CODE_CL) {
      const columnsCL = [
        {
          title: 'Días de mora',
          field: 'daysDebt',
          type: 'numeric',
          width: '100px',
          align: 'right',
          render: invoice => {
            return (
              <Typography
                variant="body1"
                color="textSecondary"
                component="div"
                noWrap
              >
                <Box fontWeight="fontWeightBold">{invoice.daysDebt}</Box>
              </Typography>
            );
          },
        },
        {
          title: 'Excedentes vigentes',
          field: 'amountRetention',
          type: 'string',
          width: '100px',
          align: 'right',
          render: invoice => {
            return (
              <Typography
                variant="body1"
                color="textSecondary"
                component="div"
                noWrap
              >
                <Box fontWeight="fontWeightBold">
                  <CountryFormatHelper
                    value={invoice.amountRetention}
                    countryId={countryId}
                    variant="currency"
                  />
                </Box>
              </Typography>
            );
          },
        },
        {
          title: 'PDF',
          field: 'pdf',
          sorting: false,
          width: '100px',
          render: rowData =>
            pdfIsDeleting && rowData.id === updatingInvoiceId ? (
              <CircularProgress size={25} color="secondary" />
            ) : (
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="flex-start"
              >
                {rowData.attachmentId ? (
                  <>
                    <Tooltip title="PDF Subido">
                      <Box color="success.main" display="inline-block">
                        <IconButton onClick={() => {}} color="inherit">
                          <UploadIcon />
                        </IconButton>
                      </Box>
                    </Tooltip>
                    <Tooltip title="Eliminar PDF">
                      <IconButton
                        onClick={() =>
                          handleDeletePdf(rowData.id, rowData.attachmentId)
                        }
                      >
                        <DeleteIcon color="secondary" />
                      </IconButton>
                    </Tooltip>
                  </>
                ) : (
                  <IconButton
                    onClick={() => handleShowPdfUploadDialog(rowData.id)}
                    component="label"
                    color="default"
                  >
                    <UploadIcon />
                  </IconButton>
                )}
              </Grid>
            ),
        },
      ];

      columns.splice(5, 0, ...columnsCL);
    }

    return columns;
  };

  return (
    <>
      {showUploadPdfDialog && (
        <UploadFileDialog
          open={showUploadPdfDialog}
          handleCloseDialog={handleClosePdfUploadDialog}
          handleSubmit={handleSubmitFile}
          accept=".pdf"
          isLoading={pdfIsUploading}
          error={pdfIsUploadingError}
          title="Selecciona el archivo .pdf que deseas subir"
        />
      )}
      <MaterialTable
        style={{ width: '100%', boxShadow: 'none' }}
        title=""
        columns={getColumns()}
        data={tableData}
        isLoading={isLoading}
        onChangeRowsPerPage={rowsPerPage => handleChangePage(0, rowsPerPage)}
        localization={{
          pagination: {
            labelDisplayedRows: '{count} facturas totales',
            labelRowsSelect: 'facturas',
            labelRowsPerPage: 'Facturas por página',
          },
          toolbar: {
            nRowsSelected: count =>
              count > 1
                ? `${count} facturas seleccionadas`
                : '1 factura seleccionada',
            searchTooltip: 'Buscar',
            searchPlaceholder: 'Buscar',
          },
          header: {
            actions: 'Acciones',
          },
          body: {
            emptyDataSourceMessage: 'No hay facturas para mostrar',
            filterRow: {
              filterTooltip: 'Filtro',
            },
          },
        }}
        options={{
          emptyRowsWhenPaging: false,
          showFirstLastPageButtons: false,
          sorting: true,
          pageSize,
          pageSizeOptions: [5, 10, 20, 50, 100],
        }}
        components={{
          Pagination: props => (
            <PaginationMaterialTable
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
              count={totalData}
              page={page - 1}
              onChangePage={(e, selectedPage) =>
                // eslint-disable-next-line react/prop-types
                handleChangePage(selectedPage, props.rowsPerPage)
              }
            />
          ),
        }}
      />
    </>
  );
});

InvoiceTableOrderDetails.propTypes = {
  countryId: PropTypes.oneOf(AVAILABLE_COUNTRIES).isRequired,
  tableData: PropTypes.arrayOf(PropTypes.object).isRequired,
  isLoading: PropTypes.bool.isRequired,
  updateInvoices: PropTypes.func.isRequired,
  pagination: PropTypes.shape({
    totalData: PropTypes.number,
    page: PropTypes.number,
    pageSize: PropTypes.number,
  }).isRequired,
};

export default InvoiceTableOrderDetails;
