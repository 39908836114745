import axios from '../../helpers/axios';
import {
  FETCH_ORDER_DETAIL_START,
  FETCH_ORDER_DETAIL_SUCCESS,
  FETCH_ORDER_DETAIL_ERROR,
  FETCH_ORDER_INVOICES_START,
  FETCH_ORDER_INVOICES_SUCCESS,
  FETCH_ORDER_INVOICES_ERROR,
  RESET_ORDER_DETAILS,
  FETCH_INVOICE_DOCUMENTS_START,
  FETCH_INVOICE_DOCUMENTS_SUCCESS,
  FETCH_INVOICE_DOCUMENTS_ERROR,
  DOWNLOAD_INVOICE_DOCUMENTS_START,
  DOWNLOAD_INVOICE_DOCUMENTS_SUCCESS,
  DOWNLOAD_INVOICE_DOCUMENTS_ERROR,
  UPLOAD_ORDER_INVOICE_PDF_START,
  UPLOAD_ORDER_INVOICE_PDF_SUCCESS,
  UPLOAD_ORDER_INVOICE_PDF_FAILURE,
  DELETE_ORDER_INVOICE_PDF_START,
  DELETE_ORDER_INVOICE_PDF_SUCCESS,
  DELETE_ORDER_INVOICE_PDF_FAILURE,
  DOWNLOAD_ORDER_INVOICE_SIMULATION_START,
  DOWNLOAD_ORDER_INVOICE_SIMULATION_SUCCESS,
  DOWNLOAD_ORDER_INVOICE_SIMULATION_FAILURE,
  FETCH_ORDER_INVOICE_HAS_ATTACHMENTS_START,
  FETCH_ORDER_INVOICE_HAS_ATTACHMENTS_SUCCESS,
  FETCH_ORDER_INVOICE_HAS_ATTACHMENTS_FAILURE,
  RESET_PDF_WAS_UPLOADED_STATE,
} from './types';
import { t13s } from '../../translationKeys';
import { b64toBlob, download } from '../../helpers/fileHandling';
import { enqueueSnackbar } from './notificationActions';

export const checkOrderInvoiceAttachments = orderInvoicesIds => dispatch => {
  dispatch({ type: FETCH_ORDER_INVOICE_HAS_ATTACHMENTS_START });
  axios
    .post(`/api/orderinvoice/hasattachments`, {
      orderInvoicesIds,
    })
    .then(({ data }) => {
      dispatch({
        type: FETCH_ORDER_INVOICE_HAS_ATTACHMENTS_SUCCESS,
        payload: data,
      });
    })
    .catch(err => {
      const errorCode = err?.response?.data?.errorCode;
      dispatch({
        type: FETCH_ORDER_INVOICE_HAS_ATTACHMENTS_FAILURE,
        payload: { errorCode },
      });
      dispatch(
        enqueueSnackbar({
          message: errorCode,
          options: {
            variant: 'error',
          },
        })
      );
    });
};

// eslint-disable-next-line import/prefer-default-export
export const fetchOrderDetail = (orderId, businessId) => dispatch => {
  dispatch({ type: FETCH_ORDER_DETAIL_START });
  axios
    .get(`/api/business/${businessId}/order/${orderId}`)
    .then(res => {
      const { OrderDetail } = res.data;

      dispatch({
        type: FETCH_ORDER_DETAIL_SUCCESS,
        payload: { id: orderId, resume: OrderDetail },
      });
    })
    .catch(err => {
      dispatch({ type: FETCH_ORDER_DETAIL_ERROR, payload: err });
    });
};

export const fecthOrderInvoices =
  (orderId, businessId, page, pageSize) => dispatch => {
    dispatch({ type: FETCH_ORDER_INVOICES_START });
    axios
      .get(
        `/api/business/${businessId}/order/${orderId}/invoice?size=${pageSize}&page=${page}`
      )
      .then(res => {
        const { data: invoices, pagination = {} } = res.data;
        const orderInvoicesIds = invoices.map(invoice => invoice.id);
        dispatch(checkOrderInvoiceAttachments(orderInvoicesIds));
        dispatch({
          type: FETCH_ORDER_INVOICES_SUCCESS,
          payload: { invoices, pagination },
        });
      })
      .catch(err => {
        dispatch({ type: FETCH_ORDER_INVOICES_ERROR, payload: err });
      });
  };

export const resetOrderDetail = () => dispatch => {
  dispatch({ type: RESET_ORDER_DETAILS });
};

export const getInvoiceDocuments = (businessId, orderId) => dispatch => {
  dispatch({ type: FETCH_INVOICE_DOCUMENTS_START });
  axios
    .get(`/api/business/${businessId}/order/${orderId}/bill`)
    .then(({ data }) => {
      dispatch({
        type: FETCH_INVOICE_DOCUMENTS_SUCCESS,
        payload: { invoiceDocuments: data },
      });
    })
    .catch(err => {
      const errorCode = err?.response?.data?.errorCode;
      dispatch({
        type: FETCH_INVOICE_DOCUMENTS_ERROR,
        payload: { errorCode },
      });
      dispatch(
        enqueueSnackbar({
          message: t13s.NOTIFICATION.FETCH_INVOICE_DOCUMENTS_ERROR,
          options: {
            variant: 'error',
          },
        })
      );
    });
};

export const downloadInvoiceDocuments = (orderId, invoiceId) => dispatch => {
  dispatch({ type: DOWNLOAD_INVOICE_DOCUMENTS_START });
  axios
    .get(`/api/order/${orderId}/bill/${invoiceId}`)
    .then(({ data }) => {
      dispatch({
        type: DOWNLOAD_INVOICE_DOCUMENTS_SUCCESS,
      });
      const { file } = data;
      let blob;
      let extension = 'pdf';
      if (file.includes('base64')) {
        const fileData = file.split(';base64,');
        extension = fileData[0].split(/\//)?.[1];
        blob = b64toBlob(
          fileData[1].replace(/={1,2}$/, ''),
          extension === 'pdf' ? 'application/pdf' : `image/${extension}`
        );
      } else {
        blob = b64toBlob(file, 'application/pdf');
      }
      const name = `${invoiceId}.${extension}`;
      return download(blob, name);
    })
    .catch(err => {
      const errorCode = err?.response?.data?.errorCode;
      dispatch({
        type: DOWNLOAD_INVOICE_DOCUMENTS_ERROR,
        payload: { errorCode },
      });
      dispatch(
        enqueueSnackbar({
          message: errorCode,
          options: {
            variant: 'error',
          },
        })
      );
    });
};

export const downloadOrderSimulation = (businessId, orderId) => dispatch => {
  dispatch({ type: DOWNLOAD_ORDER_INVOICE_SIMULATION_START });
  axios
    .get(`/api/business/${businessId}/order/${orderId}/simulationpdf`)
    .then(({ data }) => {
      dispatch({
        type: DOWNLOAD_ORDER_INVOICE_SIMULATION_SUCCESS,
      });
      const { pdf: file } = data;
      let blob;
      let extension = 'pdf';

      if (file.includes('base64')) {
        const fileData = file.split(';base64,');
        extension = fileData[0].split(/\//)?.[1];
        blob = b64toBlob(
          fileData[1].replace(/={1,2}$/, ''),
          extension === 'pdf' ? 'application/pdf' : `image/${extension}`
        );
      } else {
        blob = b64toBlob(file, 'application/pdf');
      }
      const name = `${orderId}.${extension}`;
      return download(blob, name);
    })
    .catch(err => {
      const errorCode = err?.response?.data?.errorCode;
      dispatch({
        type: DOWNLOAD_ORDER_INVOICE_SIMULATION_FAILURE,
        payload: { errorCode },
      });
      dispatch(
        enqueueSnackbar({
          message: errorCode,
          options: {
            variant: 'error',
          },
        })
      );
    });
};

export const uploadInvoicePdf = (invoiceId, pdf) => dispatch => {
  dispatch({
    type: UPLOAD_ORDER_INVOICE_PDF_START,
    payload: { updatingInvoiceId: invoiceId },
  });
  const formData = new FormData();
  formData.append('pdf', pdf, pdf.name);
  axios
    .post(`/api/orderinvoice/${invoiceId}/pdfinvoice`, formData)
    .then(({ data }) => {
      dispatch({ type: UPLOAD_ORDER_INVOICE_PDF_SUCCESS, payload: data });
    })
    .catch(err => {
      const errorCode = err?.response?.data?.errorCode;
      dispatch({
        type: UPLOAD_ORDER_INVOICE_PDF_FAILURE,
        payload: { errorCode },
      });
      dispatch(
        enqueueSnackbar({
          message: errorCode,
          options: {
            variant: 'error',
          },
        })
      );
    });
};

export const deleteInvoicePdf = (invoiceId, pdfId) => dispatch => {
  dispatch({
    type: DELETE_ORDER_INVOICE_PDF_START,
    payload: { uddatingInvoiceId: invoiceId },
  });
  axios
    .delete(`/api/orderinvoice/${invoiceId}/pdfinvoice`, { params: { pdfId } })
    .then(() => {
      dispatch({
        type: DELETE_ORDER_INVOICE_PDF_SUCCESS,
        payload: { deletedPdfId: pdfId },
      });
    })
    .catch(err => {
      const errorCode = err?.response?.data?.errorCode;
      dispatch({
        type: DELETE_ORDER_INVOICE_PDF_FAILURE,
        payload: { errorCode },
      });
      dispatch(
        enqueueSnackbar({
          message: errorCode,
          options: {
            variant: 'error',
          },
        })
      );
    });
};

export const resetPdfWasUploadedState = () => dispatch => {
  dispatch({ type: RESET_PDF_WAS_UPLOADED_STATE });
};
