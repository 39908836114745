import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Grid, Typography, Tooltip, Box, makeStyles } from '@material-ui/core';
import HelpIcon from '../icons/HelpIcon';
import { t13s } from '../../translationKeys';
import CountryFormatHelper from './CountryFormatHelper';
import { COUNTRY_CODE_CL } from '../../helpers/constants';

const useStyles = makeStyles(theme => ({
  rowRoot: {
    display: 'grid',
    gridTemplateColumns: '65% 35%',
    gridTemplateRows: 'auto',
    marginTop: 10,
  },
  labelArea: {
    gridColumn: '1 / 2',
  },
  valueArea: {
    gridColumn: '2 / 3',
  },
  greenTitle: {
    color: theme.palette.text.contrast1,
    marginTop: 20,
    marginBottom: 10,
  },
  labelWithTooltip: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  tooltipIcon: {
    marginLeft: 6,
  },
  sendButton: {
    marginTop: 15,
  },
}));

const OperationResume = ({
  simulation: {
    retentionPct,
    retention,
    baseRate,
    interest,
    operationCost,
    transfer,
    totalAmountCreditNotes,
  } = {},
  showButton,
  buttonComponent,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { countryId } = useSelector(state => state.businessState.business);

  const advancePercentage = !Number.isNaN(retentionPct)
    ? 100 - retentionPct
    : null;

  const getTooltip = () => {
    return countryId === COUNTRY_CODE_CL
      ? 'Tasa de interés ponderada de las facturas de tu operación'
      : 'Tasa de interés mensual';
  };

  return (
    <Grid container align="left">
      <Grid container className={classes.rowRoot}>
        <Typography
          variant="body1"
          color="textPrimary"
          className={classes.labelArea}
          component="div"
        >
          <Box fontWeight="fontWeightBold">% Anticipo:</Box>
        </Typography>
        <Typography
          variant="body1"
          color="textPrimary"
          align="right"
          className={classes.valueArea}
          component="div"
        >
          <Box fontWeight="fontWeightBold">
            <CountryFormatHelper
              value={advancePercentage}
              variant="percentage"
              countryId={countryId}
              dataQa="operation-resume-porcentaje-anticipo-string"
            />
          </Box>
        </Typography>
      </Grid>

      <Grid container className={classes.rowRoot}>
        <Typography
          variant="body1"
          color="textPrimary"
          className={classes.labelArea}
          component="div"
        >
          <Box fontWeight="fontWeightBold">Excedentes:</Box>
        </Typography>
        <Typography
          variant="body1"
          color="textPrimary"
          align="right"
          className={classes.valueArea}
          component="div"
        >
          <Box fontWeight="fontWeightBold">
            <CountryFormatHelper
              value={retention || '-'}
              countryId={countryId}
              variant="currency"
              dataQa="operation-resume-excedentes-string"
            />
          </Box>
        </Typography>
      </Grid>

      <Grid container className={classes.rowRoot}>
        <Typography
          variant="body1"
          color="textPrimary"
          className={classes.labelArea}
          component="div"
        >
          <Box fontWeight="fontWeightBold" className={classes.labelWithTooltip}>
            Tasa de interés:
            <Tooltip title={getTooltip()}>
              <span>
                <HelpIcon
                  color="secondary"
                  fontSize="small"
                  className={classes.tooltipIcon}
                />
              </span>
            </Tooltip>
          </Box>
        </Typography>
        <Typography
          variant="body1"
          color="textPrimary"
          align="right"
          className={classes.valueArea}
          component="div"
        >
          <Box fontWeight="fontWeightBold">
            <CountryFormatHelper
              value={baseRate}
              variant="percentage"
              useFixedDecimalPrecision={2}
              countryId={countryId}
              dataQa="operation-resume-tasa-interes-string"
            />
          </Box>
        </Typography>
      </Grid>

      <Grid container className={classes.rowRoot}>
        <Typography
          variant="body1"
          color="textPrimary"
          className={classes.labelArea}
          component="div"
        >
          <Box fontWeight="fontWeightBold">Diferencia de precio:</Box>
        </Typography>
        <Typography
          variant="body1"
          color="textPrimary"
          align="right"
          className={classes.valueArea}
          component="div"
        >
          <Box fontWeight="fontWeightBold">
            <CountryFormatHelper
              value={interest || '-'}
              countryId={countryId}
              variant="currency"
              dataQa="operation-resume-diferencia-precio-string"
            />
          </Box>
        </Typography>
      </Grid>

      <Grid container className={classes.rowRoot}>
        <Typography
          variant="body1"
          color="textPrimary"
          className={classes.labelArea}
          component="div"
        >
          <Box fontWeight="fontWeightBold">Asesoría digital:</Box>
        </Typography>
        <Typography
          variant="body1"
          color="textPrimary"
          align="right"
          className={classes.valueArea}
          component="div"
        >
          <Box fontWeight="fontWeightBold">
            <CountryFormatHelper
              value={operationCost || '-'}
              countryId={countryId}
              variant="currency"
              dataQa="operation-resume-asesoria-digital-string"
            />
          </Box>
        </Typography>
      </Grid>

      <Grid container className={classes.rowRoot}>
        <Typography
          variant="body1"
          color="textPrimary"
          className={classes.labelArea}
          component="div"
        >
          <Box fontWeight="fontWeightBold">Descuentos a facturas:</Box>
        </Typography>
        <Typography
          variant="body1"
          color="textPrimary"
          align="right"
          className={classes.valueArea}
          component="div"
        >
          <Box fontWeight="fontWeightBold">
            <CountryFormatHelper
              value={totalAmountCreditNotes || '-'}
              countryId={countryId}
              variant="currency"
            />
          </Box>
        </Typography>
      </Grid>

      <Grid item xs={12} align="center">
        <Typography variant="h6" className={classes.greenTitle}>
          {t(t13s.LABEL.TOTAL_TO_TRANSFER)}
        </Typography>
        <Typography variant="h5" component="div">
          <Box fontWeight="fontWeightBold">
            <CountryFormatHelper
              value={transfer || '-'}
              countryId={countryId}
              variant="currency"
              dataQa="operation-resume-total-transferir-string"
            />
          </Box>
        </Typography>
      </Grid>
      {showButton && (
        <Grid item xs={12} className={classes.sendButton}>
          {buttonComponent}
        </Grid>
      )}
    </Grid>
  );
};

OperationResume.defaultProps = {
  showButton: false,
  buttonComponent: null,
};

OperationResume.propTypes = {
  simulation: PropTypes.shape({
    retentionPercentage: PropTypes.number,
    retentionAmount: PropTypes.number,
    baseRate: PropTypes.number,
    interestAmount: PropTypes.number,
    operationCostAmount: PropTypes.number,
    toTransferAmount: PropTypes.number,
  }).isRequired,
  showButton: PropTypes.bool,
  buttonComponent: PropTypes.element,
};
export default OperationResume;
