import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles, Grid, Typography, Box, Link } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { AVAILABLE_COUNTRIES } from '../helpers/constants';
import PaymentsInvoiceTable from './tables/PaymentsInvoiceTable';
import PaymentsInvoiceTableToolbar from './elements/PaymentsInvoiceTableToolbar';
import Card from './elements/Card';
import { paymentsRestoreInvoice } from '../store/actions/paymentsActions';
import useInvoiceTable from '../hooks/payments/useInvoiceTable';

import { SEGMENT_EVENTS } from '../helpers/segment/constants';
import { trackSegment } from '../helpers/segment/trackingSegment';

const TABLE_NAME = 'paid';

const useStyles = makeStyles(theme => ({
  card: {
    width: '100%',
  },
  tableInvoicesContainer: {
    alignContent: 'flex-start',
    display: 'flex',
    flexFlow: 'column nowrap',
    width: '100%',
    height: '100%',
  },
  invoicePayrollColumn: {
    minWidth: 160,
    color: theme.palette.text.secondary,
  },
  invoicePayrollLink: {
    textDecoration: 'underline',
  },
}));

const PaymentsPaidTable = React.memo(props => {
  const { countryId, businessId } = props;
  const { pagination } = useSelector(state => state.paymentsState);
  const { page, pageSize } = pagination;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [search, setSearch] = useState(null);
  const { resetPagination, updateInvoices } = useInvoiceTable();
  const history = useHistory();

  // eslint-disable-next-line no-unused-vars
  /* const downloadInvoice = ({ invoice, closeMenu }) => {
    closeMenu();
  }; */

  // eslint-disable-next-line no-unused-vars
  /* const emailInvoice = ({ invoice, closeMenu }) => {
    closeMenu();
  }; */

  const rows = [
    {
      hide: true,
      field: 'paymentDetail.createdAt',
    },
    {
      position: 55,
      title: 'Nomina',
      field: 'payroll',
      type: 'string',
      align: 'left',
      hide: false,
      render: invoice => (
        <Typography
          variant="body1"
          color="textPrimary"
          component="div"
          className={classes.invoicePayrollColumn}
        >
          <Link
            variant="body1"
            component="button"
            onClick={() => history.push(`/payrolls/${invoice.payroll.id}`)}
            color="inherit"
            className={classes.invoicePayrollLink}
          >
            <Box fontWeight="fontWeightBold" textAlign="left">
              {invoice?.payroll?.name}
            </Box>
          </Link>
        </Typography>
      ),
    },
  ];

  const actionsButtons = [
    {
      text: 'Devolver a mis facturas',
      action: ({ invoice, closeMenu }) => {
        dispatch(paymentsRestoreInvoice(businessId, [invoice], 'paid'));
        closeMenu();
      },
    },
    /* {
      text: 'Descargar comprobante',
      action: downloadInvoice,
    },
    {
      text: 'Enviar por correo',
      action: emailInvoice,
    }, */
  ];

  const handleSearchChange = value => {
    resetPagination();
    setSearch(value);
  };

  const handleUpdateInvoices = (newPage, newPageSize) => {
    updateInvoices({
      page: newPage + 1,
      pageSize: newPageSize,
      search,
      option: TABLE_NAME,
    });
  };

  useEffect(() => {
    updateInvoices({ page, pageSize, search, option: TABLE_NAME });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    trackSegment(SEGMENT_EVENTS.PAYMENT_PAID_VIEWED, {
      businessId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container item className={classes.tableInvoicesContainer}>
      <PaymentsInvoiceTableToolbar
        title="Revisa el historial de facturas pagadas"
        onChangeSearch={handleSearchChange}
      />

      <Card variant="white" border className={classes.card}>
        <PaymentsInvoiceTable
          rows={rows}
          actionsButtons={actionsButtons}
          countryId={countryId}
          handleUpdateInvoices={handleUpdateInvoices}
        />
      </Card>
    </Grid>
  );
});

PaymentsPaidTable.propTypes = {
  countryId: PropTypes.oneOf(AVAILABLE_COUNTRIES).isRequired,
  businessId: PropTypes.number.isRequired,
};

export default PaymentsPaidTable;
