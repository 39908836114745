import axios from '../../helpers/axios';
import {
  FETCH_WALLET_STATUS_START,
  FETCH_WALLET_STATUS_SUCCESS,
  FETCH_WALLET_STATUS_ERROR,
} from './types';

// eslint-disable-next-line import/prefer-default-export
export const fetchWalletStatus = businessId => dispatch => {
  dispatch({ type: FETCH_WALLET_STATUS_START });
  axios
    .get(`api/business/${businessId}/wallet/status`)
    .then(res => {
      const resData = res.data;
      dispatch({ type: FETCH_WALLET_STATUS_SUCCESS, payload: resData });
    })
    .catch(err => {
      const errorCode = err?.response?.data?.errorCode;
      dispatch({ type: FETCH_WALLET_STATUS_ERROR, payload: errorCode });
    });
};
