import React, { useEffect } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import DebtServiceCard from './elements/DebtServiceCard';
import { convertSpacingToCss } from '../helpers/stylesHelpers';
import { fetchDebtService } from '../store/actions/debtServiceActions';
import SurplusCard from './elements/SurplusCard';
import { features } from '../config/features';
import { fetchWalletStatus } from '../store/actions/walletActions';

const useStyles = makeStyles({
  root: {
    padding: convertSpacingToCss('lg xl'),
    justifyContent: 'space-between',
    display: 'flex',
    height: 161,
  },
});

const HomeCards = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const countryId = useSelector(
    state => state.businessState.business.countryId
  );
  const businessId = useSelector(state => state.businessState.business.id);
  const { balance, lastPendingWalletRequest, walletStatusIsLoading } =
    useSelector(state => state.walletState);
  const countryFeatures = features[countryId];

  useEffect(() => {
    if (businessId) {
      dispatch(fetchDebtService(businessId, 'business'));
      dispatch(fetchWalletStatus(businessId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Grid className={classes.root}>
      <Grid item container direction="row">
        <Grid item key="CARD_BURO_CREDITO">
          <DebtServiceCard countryId={countryId} />
        </Grid>
      </Grid>
      {countryFeatures.cards.hasSurplus && (
        <Grid item>
          <SurplusCard
            loading={walletStatusIsLoading}
            countryId={countryId}
            balance={balance}
            lastPendingWalletRequest={lastPendingWalletRequest}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default HomeCards;
