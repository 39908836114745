// eslint-disable-next-line import/prefer-default-export
export const NOTIFICATION = {
  SESSION_EXPIRED: 'NOTIFICATION_SESSION_EXPIRED',
  CAN_NOT_FETCH_CART_INVOICES: 'NOTIFICATION_CAN_NOT_FETCH_CART_INVOICES',
  CAN_NOT_FETCH_CART_INVOICES_COUNT:
    'NOTIFICATION_CAN_NOT_FETCH_CART_INVOICES_COUNT',
  CAN_NOT_FETCH_CART_INVOICES_TOTAL_AMOUNT:
    'NOTIFICATION_CAN_NOT_FETCH_CART_INVOICES_TOTAL_AMOUNT',
  FORGOT_PASSWORD_SUCCESS: 'NOTIFICATION_FORGOT_PASSWORD_SUCCESS',
  USER_CREATED_SUCCESS: 'NOTIFICATION_USER_CREATED_SUCCESS',
  BUSINESS_CREATED: 'NOTIFICATION_BUSINESS_CREATED',
  UPDATE_SUCCESS: 'NOTIFICATION_UPDATE_SUCCESS',
  UPDATE_ERROR: 'NOTIFICATION_UPDATE_ERROR',
  TAX_SERVICE_CREDENTIAL_CREATED_SUCCESS:
    'NOTIFICATION_TAX_SERVICE_CREDENTIAL_CREATED_SUCCESS',
  RESET_PASSWORD_SUCCESS: 'NOTIFICATION_RESET_PASSWORD_SUCCESS',
  UPDATE_PASSWORD_SUCCESS: 'NOTIFICATION_UPDATE_PASSWORD_SUCCESS',
  UPDATE_BUSINESS_SUCCESS: 'NOTIFICATION_UPDATE_BUSINESS_SUCCESS',
  UPDATE_BUSINESS_ERROR: 'NOTIFICATION_UPDATE_BUSINESS_ERROR',
  FETCH_STAKEHOLDERS_ERROR: 'NOTIFICATION_FETCH_STAKEHOLDERS_ERROR',
  CREATE_STAKEHOLDERS_SUCCESS: 'NOTIFICATION_CREATE_STAKEHOLDERS_SUCCESS',
  EDIT_STAKEHOLDERS_SUCCESS: 'NOTIFICATION_EDIT_STAKEHOLDERS_SUCCESS',
  DELETE_STAKEHOLDERS_SUCCESS: 'NOTIFICATION_DELETE_STAKEHOLDERS_SUCCESS',
  DOWNLOAD_DOCUMENT_ERROR: 'NOTIFICATION_DOWNLOAD_DOCUMENT_ERROR',
  UPLOAD_DOCUMENT_SUCCESS: 'NOTIFICATION_UPLOAD_DOCUMENT_SUCCESS',
  COPY_DATA_TO_CLIPBOARD_SUCCESS: 'NOTIFICATION_COPY_DATA_TO_CLIPBOARD_SUCCESS',
  COPY_DATA_TO_CLIPBOARD_FAILURE: 'NOTIFICATION_COPY_DATA_TO_CLIPBOARD_FAILURE',
  COPY_EMAIL_SUCCESS: 'NOTIFICATION_COPY_EMAIL_SUCCESS',
  CREATE_BANK_ACCOUNT_SUCCESS: 'NOTIFICATION_CREATE_BANK_ACCOUNT_SUCCESS',
  UPDATE_BANK_ACCOUNT_SUCCESS: 'NOTIFICATION_UPDATE_BANK_ACCOUNT_SUCCESS',
  UPDATE_DEFAULT_BANK_ACCOUNT_SUCCESS:
    'NOTIFICATION_UPDATE_DEFAULT_BANK_ACCOUNT_SUCCESS',
  DELETE_BANK_ACCOUNT_SUCCESS: 'NOTIFICATION_DELETE_BANK_ACCOUNT_SUCCESS',
  CREATE_CONTACT_DETAIL_SUCCESS: 'NOTIFICATION_CREATE_CONTACT_DETAIL_SUCCESS',
  UPDATE_CONTACT_DETAIL_SUCCESS: 'NOTIFICATION_UPDATE_CONTACT_DETAIL_SUCCESS',
  DELETE_CONTACT_DETAIL_SUCCESS: 'NOTIFICATION_DELETE_CONTACT_DETAIL_SUCCESS',
  FETCH_CONTACT_DETAIL_FAILURE: 'NOTIFICATION_FETCH_CONTACT_DETAIL_FAILURE',
  FETCH_INVOICE_DOCUMENTS_ERROR: 'NOTIFICATION_FETCH_INVOICE_DOCUMENTS_ERROR',
  PAYMENTS_FETCH_ERROR: 'NOTIFICATION_PAYMENTS_FETCH_ERROR',
  PAYMENTS_ARCHIVE_ERROR: 'NOTIFICATION_PAYMENTS_ARCHIVE_ERROR',
  PAYMENTS_PAY_ERROR: 'NOTIFICATION_PAYMENTS_PAY_ERROR',
  PAYMENTS_RESTORE_ERROR: 'NOTIFICATION_PAYMENTS_RESTORE_ERROR',
  PAYMENTS_CHANGE_PAYMENT_DATE_ERROR:
    'NOTIFICATION_PAYMENTS_CHANGE_PAYMENT_DATE_ERROR',
  PAYMENTS_FETCH_STATS_ERROR: 'NOTIFICATION_PAYMENTS_FETCH_STATS_ERROR',
  PAYMENTS_NO_SELECTED_INVOICES: 'NOTIFICATION_PAYMENTS_NO_SELECTED_INVOICES',
  CREATE_PAYROLL_SUCCESS: 'NOTIFICATION_CREATE_PAYROLL_SUCCESS',
  UPDATE_PAYROLL_SUCCESS: 'NOTIFICATION_UPDATE_PAYROLL_SUCCESS',
  DELETE_PAYROLL_SUCCESS: 'NOTIFICATION_DELETE_PAYROLL_SUCCESS',
  CREATE_PAYROLL_FAILURE: 'NOTIFICATION_CREATE_PAYROLL_FAILURE',
  UPDATE_PAYROLL_FAILURE: 'NOTIFICATION_UPDATE_PAYROLL_FAILURE',
  DELETE_PAYROLL_FAILURE: 'NOTIFICATION_DELETE_PAYROLL_FAILURE',
  FETCH_PAYROLLS_FAILURE: 'NOTIFICATION_FETCH_PAYROLLS_FAILURE',
  FETCH_PAYROLL_FAILURE: 'NOTIFICATION_FETCH_PAYROLL_FAILURE',
  PAY_PAYROLL_FAILURE: 'NOTIFICATION_PAY_PAYROLL_FAILURE',
  FINANCING_PAYROLL_FAILURE: 'NOTIFICATION_FINANCING_PAYROLL_FAILURE',
  LOAD_DOCUMENT_FAILURE: 'NOTIFICATION_LOAD_DOCUMENT_FAILURE',
  UPLOAD_VOUCHER_FAILURE: 'NOTIFICATION_UPLOAD_VOUCHER_FAILURE',
  NOTIFICATION_FETCH_SUPPLIERS_ERROR: 'No se pudo obtener los proveedores.',
  UPDATE_SUPPLIER_ERROR: 'No se pudo actualizar el proveedor.',
  FETCH_ONBOARD_FAILURE: 'NOTIFICATION_FETCH_ONBOARD_FAILURE',
  FETCH_ONBOARD_SUPPLIERS_FAILURE:
    'NOTIFICATION_FETCH_ONBOARD_SUPPLIERS_FAILURE',
  SAVE_ONBOARD_FAILURE: 'NOTIFICATION_SAVE_ONBOARD_FAILURE',
  ONBOARD_DAYS_OUT_OF_RANGE: 'NOTIFICATION_ONBOARD_DAYS_OUT_OF_RANGE',
  REGISTER_PFX_SUCCESS: 'NOTIFICATION_REGISTER_PFX_SUCCESS',
  FETCH_CREDIT_LINE_FAILURE: 'FETCH_CREDIT_LINE_FAILURE',
};
