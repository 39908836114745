import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Grid, makeStyles, Typography, Button, Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import Card from './Card';
import excedentes from '../../assets/excedentes.svg';
import excedentes2 from '../../assets/excedentes2.svg';
import CountryFormatHelper from './CountryFormatHelper';
import { AVAILABLE_COUNTRIES, LONG_DATE_FORMAT } from '../../helpers/constants';

const useStyles = makeStyles(theme => ({
  rootCardExcedentes: {
    padding: '15px 10px',
    minHeight: 145,
    height: 145,
    width: 410,
  },
  icon: {
    marginLeft: 14,
    marginRight: 23,
  },
  title: {
    marginBottom: 6,
  },
  body: {
    marginBottom: 25,
  },
  availableLabel: {
    color: theme.palette.text.contrast1,
    marginBottom: 10,
  },
}));

const SurplusCard = props => {
  const classes = useStyles();
  const { balance, loading, countryId, lastPendingWalletRequest = {} } = props;

  // this function was added to avoid rounding up to zero keeping the - sign (edge case. i.e.: -0.12345 )
  const fixBalance = () => {
    const fixedBalance = balance < 0 && balance > -0.5 ? 0 : balance;
    return Number.parseFloat(fixedBalance)
      ? parseFloat(fixedBalance).toFixed(0)
      : fixedBalance;
  };

  const hasPendingWalletRequests = !!lastPendingWalletRequest;
  const finalBalance = fixBalance();
  const balanceImage = finalBalance ? excedentes2 : excedentes;

  const getButtonText = () => {
    if (finalBalance > 0 && hasPendingWalletRequests) {
      return 'Excedentes solicitados';
    }
    return 'Solicitar devolución';
  };

  const getAmountText = () => {
    if (finalBalance < 0) {
      return 'Deuda';
    }
    if (hasPendingWalletRequests) {
      return `Solicitados el ${moment(
        lastPendingWalletRequest.createdAt
      ).format(LONG_DATE_FORMAT)}`;
    }
    return 'Disponibles';
  };

  const requestSurplus = () => {
    if (window.hsTunnel) {
      window.hsTunnel(balance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.'));
    }
  };

  const noSurplusBody = (
    <Typography variant="body2" color="textSecondary" className={classes.body}>
      Dado el estado actual de tus
      <br />
      operaciones, no tienes excedentes
      <br />
      disponibles para solicitar
    </Typography>
  );

  return (
    <Grid container>
      <Grid item>
        <Card variant={finalBalance > 0 ? 'green' : 'grey'}>
          <Grid
            container
            direction="row"
            className={classes.rootCardExcedentes}
          >
            <Grid item>
              <img
                src={balanceImage}
                width={120}
                className={classes.icon}
                alt=""
              />
            </Grid>
            <Grid item>
              <Typography
                variant="h6"
                color="textPrimary"
                component="div"
                className={classes.title}
              >
                <Box fontWeight="fontWeightBold">Excedentes</Box>
              </Typography>
              {loading ? (
                <Skeleton variant="text" />
              ) : finalBalance ? (
                <>
                  <Typography
                    variant="h5"
                    color="textPrimary"
                    component="div"
                    className={classes.amount}
                  >
                    <Box fontWeight="fontWeightBold">
                      <CountryFormatHelper
                        value={finalBalance}
                        countryId={countryId}
                        variant="currency"
                      />
                    </Box>
                  </Typography>
                  <Typography
                    variant="body1"
                    className={classes.availableLabel}
                  >
                    {getAmountText()}
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={requestSurplus}
                    disabled={finalBalance <= 0 || hasPendingWalletRequests}
                  >
                    {getButtonText()}
                  </Button>
                </>
              ) : (
                noSurplusBody
              )}
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

SurplusCard.defaultProps = {
  balance: 0,
  loading: false,
  lastPendingWalletRequest: null,
};

SurplusCard.propTypes = {
  balance: PropTypes.number,
  loading: PropTypes.bool,
  countryId: PropTypes.oneOf(AVAILABLE_COUNTRIES).isRequired,
  lastPendingWalletRequest: PropTypes.shape({
    createdAt: PropTypes.string,
  }),
};

export default SurplusCard;
