import {
  FETCH_WALLET_STATUS_START,
  FETCH_WALLET_STATUS_SUCCESS,
  FETCH_WALLET_STATUS_ERROR,
} from '../actions/types';

const initialState = {
  balance: 0,
  lastPendingWalletRequest: null,
  walletStatusIsLoading: false,
  walletStatusError: null,
};

const fetchDebtServiceStart = state => {
  return {
    ...state,
    lastPendingWalletRequest: null,
    walletStatusIsLoading: true,
    walletStatusError: null,
  };
};
const fetchDebtServiceSuccess = (state, payload) => {
  return {
    ...state,
    ...payload,
    walletStatusIsLoading: false,
  };
};
const fetchDebtServiceFailure = (state, payload) => {
  return {
    ...state,
    balance: 0,
    lastPendingWalletRequest: null,
    walletStatusIsLoading: false,
    walletStatusError: payload.errorCode,
  };
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_WALLET_STATUS_START:
      return fetchDebtServiceStart(state);
    case FETCH_WALLET_STATUS_SUCCESS:
      return fetchDebtServiceSuccess(state, payload);
    case FETCH_WALLET_STATUS_ERROR:
      return fetchDebtServiceFailure(state, payload);
    default:
      return state;
  }
};
